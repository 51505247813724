'use client';

import { useEffect } from 'react';

import { EmailLayout } from '@/app/components/layout';
import { resetAccount } from '@/lib/account/accountSlice';
import { useAppDispatch } from '@/lib/hooks';
import { resetOrder } from '@/lib/order/orderSlice';

import { EssenceHelpLink } from '../components';

const Content = (): JSX.Element => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(resetOrder());
    dispatch(resetAccount());
  }, [dispatch]);

  return (
    <EmailLayout
      title="Get Started with Oura"
      description="Please enter the email associated with your Essence Healthcare account to receive a sign-in code."
      help={<EssenceHelpLink standalone />}
      imageSrc="/eop/woman-long-hair.jpg"
      partner="essence"
      isInvalid={false}
      isMaintenance={false}
    />
  );
};

export default Content;
